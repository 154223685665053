import React from "react";

import Body from "components/layout/Body/Body";
import SEO from "components/seo";

const NotFoundPage = () => (
	<Body>
		<SEO title="404: Not found" />
		<h1>NOT FOUND</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</Body>
);

export default NotFoundPage;
